<!--
 * @Author: your name
 * @Date: 2021-03-02 17:52:37
 * @LastEditTime: 2021-03-12 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\appoint.vue
-->
<template>
  <div class="appoint">
    <van-tabs v-model="active">
      <van-tab v-for="(item,index) in tabList" :key="index">
        <template #title>
          <van-icon name />
          {{ item.clmc }}
        </template>

        <van-tree-select height="100vh" :items="item.dataSource" :main-active-index.sync="select_active" class="office" @click-item="onNavClick">
          <!-- <template #content>
            <div v-if="select_active===0">
              123
            </div>
            <div v-if="select_active===1">
              345
            </div>
          </template> -->
        </van-tree-select>
      </van-tab>
    </van-tabs>
    <van-dialog v-model="show" :title="'预约须知'" show-confirm-button theme="round-button" confirm-button-text="阅读并同意" confirm-button-color="#3A8AE5" @confirm="clickConfirm">
      <div class="body">
        <div>预约挂号成功后，请您保持联系手机畅通，便于收到我院相关信息的提醒。</div>
        <div>为保证您顺利预约挂号及就诊，请您认真阅读并遵守“预约挂号须知”，充分理解各条款内容。</div>
        <div>1、本院只预约挂号18周岁以上的患者，请真实填写就诊人员信息。</div>
        <div>2、预约挂号成功后，请在预约挂号当日时段提前付费：在“在线缴费”中完成当日挂号缴费。</div>
        <div>3、取消预约挂号：预约挂号成功后，如需取消，请在预约挂号时段的2小时前取消；若不能按时就诊，须主动取消，否则记为爽约；若90天内累计爽约3次将列入黑名单，会影响以后的预约。</div>
        <div>4、预约挂号限制：</div>
        <div class="xuzhi">
          1）最多可预约挂号28天内号源；
        </div>
        <div class="xuzhi">
          2）每人每天每个科别/医生只可预约挂号一个号源；
        </div>
        <div class="xuzhi">
          3）当日每个号源时段最后十分钟不能预约挂号该时段号源。
        </div>
        <div>5、自费患者支付挂号、检验检查、电子处方、在线续方等线上缴费；医保卡患者暂不支持线上缴费。</div>
        <div>6、目前互联网医院暂不支持开具电子发票，请谅解。</div>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import {constantModel} from '../../api/constant'
import {appointModel}  from '../../api/appoint'
export default {
  data(){
    return{
      tabList:[],
      active: 0,
      show:true,
      select_active: 0
    }
  },
  mounted() {
    this.getOfficeList()
  },
  methods:{
   async getOfficeList(){
     const promiseList = []
      const result=await constantModel.typeOutpat({fzdm:'hylx'})

        result.data.forEach((item)=> {
        promiseList.push(appointModel.selectDep({hylx:item.clz}))
        })
          const Allresult = await Promise.all(promiseList)
        result.data.forEach((tab, index) => {
        const item = Allresult[index]
          if(item && item.data){
             tab.dataSource = this.getResult(item.data|| [])
          }

      })
      // 隐藏普通门诊
      result.data.forEach(item => {
        if (item.clz != '100') {
          this.tabList.push(item)
        }
      })
      // this.tabList=result.data
      //   this.tabList
    },
    getResult(result){
      const diseaseList=[]
      if(result){
        for(let i=0;i<result.length;i++){
           let obj={}, chil=result[i].children
           obj.text = result[i].ksmc
           obj.hylx=result[i].hylx
           obj.ksdm=result[i].ksdm
           obj.ksjj=result[i].ksjj
           obj.yljgdm=result[i].yljgdm
           obj.children=[]
        for(let j=0; j<chil.length; j++){
          let s={}
          s.text = chil[j].ksmc
          s.hylx = chil[j].hylx
          s.ksdm=chil[j].ksdm
          s.ksjj=chil[j].ksjj
          s.yljgdm=chil[j].yljgdm
          obj.children.push(s)
        }
         diseaseList.push(obj)
        }
      }
      return  diseaseList
    },
    clickConfirm(){

    },
    onNavClick(item){
      const hylx=item.hylx
      const yljgdm = item.yljgdm
      const ksdm = item.ksdm
      const ksmc = item.text
      if(hylx=='102'||hylx=='103'){
        this.$router.push({
          path: '/doctor/list',
          query: {
            yljgdm:yljgdm,
            ksdm:ksdm,
            hylx:hylx
          }
        })
      }else{
        this.$router.push({
          path: '/scheduling',
          query: {
            yljgdm:yljgdm,
            ksdm:ksdm,
            id:hylx,
            ksmc:ksmc
          }
        })
      }
    }
  }
}
</script>
<style scoped lang='less'>
@import '../../assets/styles/variable.less';
.appoint {
  .van-tabs {
    top: 30px;
  }
  .office{
    margin-top:20px;
  }
}
.vant-tab {
  line-height: 50px;
  }
  .van-dialog{

  }
  /deep/.van-tabs__line{
    background-color:rgb(58, 138, 229);
  }
/deep/.van-sidebar-item--select::before{
  background-color:rgb(58, 138, 229);
}
  /deep/.van-dialog__header{
    font-size: @font-size-36;
    font-weight: bold;
    color:#333;
    padding-top:30px;

  }
 /deep/.van-dialog--round-button .van-dialog__footer{

 }
  /deep/.van-dialog--round-button .van-dialog__confirm{
    height: 74px;

  }
  .body{
    padding:0px 40px 32px 40px;
    font-size: @font-size-30;
    height:470px;
    overflow:auto;
    margin-top:32px;
  }
  .footer{
    position:absolute;
    bottom: 32px;
    width:100%;
  }
</style>
